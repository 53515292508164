/*---------------------------------------------
footer
---------------------------------------------*/

.footer{
  width:100%;
  color: $c-white;
  background-color: $c-main;
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 70px;
  @media only screen and (min-width:$tbwidth) {
    padding-top: 50px;
  }
  .toTop{
    position: absolute;
    padding: 10px;
    z-index: 20;
    right: 5px;
    bottom: 0;
    opacity: .8;
    @media only screen and (min-width:$tbwidth) {
      top: 40px;
      right:10px;
      transition: .3s;
      &:hover{
        top: 30px;
      }
    }
    @media only screen and (min-width:$pcwidth) {
      right: calc(50% - 585px);
    }
    img{
      display: block;
    }
  }//&_toTop
}//.footer
.footList{
  text-align: center;
  @media only screen and (max-width:$spwidth) {
    margin-top: -20px;
  }
  li{
    display: inline-block;
    &:not(:first-child){
      margin-left: 30px;
    }
    @media only screen and (max-width:$spwidth) {
      margin-top: 20px;
    }//sp
    a{
      color: $c-white;
      @media only screen and (min-width:$tbwidth) {
        @include hover;
      }
    }
  }//li
}//.footList
.footBnr{
  @media only screen and (max-width:$spwidth) {
    margin-top: 15px;
    text-align: center;
  }
  @media only screen and (min-width:$tbwidth) {
    margin-top: 30px;
  }

  li{
    margin-top: 20px;
    display: inline-block;
    @media only screen and (max-width:$spwidth) {
      width: 45%;
      &:nth-child(odd){
        margin-right: 15px;
      }
    }
    @media only screen and (min-width:$tbwidth) {
      &:not(:last-child){
        margin-right: 15px;
      }
      a{
        display: block;
        transition: .5s;
        &:hover{
          opacity: .8;
        }
      }
    }//tb
  }//li
}//.footBnr
