@charset "UTF-8";
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

figure {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
  outline: none;
}

/* clearfix */
.cf {
  zoom: 1;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

main {
  display: block;
}

a:hover {
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

@media only screen and (max-width: 768px) {
  .mt_XS_sp,
  .mt_XS,
  .editArea p,
  .editArea ul,
  .editArea ol {
    margin-top: 10px;
  }
  .mt_SS_sp,
  .mt_SS {
    margin-top: 10px;
  }
  .mt_S_sp,
  .mt_S,
  .list_ul,
  .list_ol,
  .editArea h4,
  .editArea h2 + p,
  .editArea img,
  .editArea table {
    margin-top: 20px;
  }
  .mt_M_sp,
  .mt_M,
  .editArea * + h2,
  .editArea h3 {
    margin-top: 30px;
  }
  .mt_L_sp,
  .mt_L {
    margin-top: 40px;
  }
  .mt_LL_sp,
  .mt_LL {
    margin-top: 50px;
  }
  .mt_XL_sp,
  .mt_XL {
    margin-top: 60px;
  }
  .mt_XXL_sp,
  .mt_XXL {
    margin-top: 80px;
  }
  .mb_XS_sp,
  .mb_XS {
    margin-bottom: 10px;
  }
  .mb_SS_sp,
  .mb_SS {
    margin-bottom: 10px;
  }
  .mb_S_sp,
  .mb_S {
    margin-bottom: 20px;
  }
  .mb_M_sp,
  .mb_M {
    margin-bottom: 30px;
  }
  .mb_L_sp,
  .mb_L {
    margin-bottom: 40px;
  }
  .mb_LL_sp,
  .mb_LL {
    margin-bottom: 50px;
  }
  .mb_XL_sp,
  .mb_XL {
    margin-bottom: 60px;
  }
  .mb_XXL_sp,
  .mb_XXL {
    margin-bottom: 80px;
  }
  .pt_XS_sp,
  .pt_XS {
    padding-top: 10px;
  }
  .pt_SS_sp,
  .pt_SS {
    padding-top: 10px;
  }
  .pt_S_sp,
  .pt_S {
    padding-top: 20px;
  }
  .pt_M_sp,
  .pt_M {
    padding-top: 30px;
  }
  .pt_L_sp,
  .pt_L {
    padding-top: 40px;
  }
  .pt_LL_sp,
  .pt_LL {
    padding-top: 50px;
  }
  .pt_XL_sp,
  .pt_XL {
    padding-top: 60px;
  }
  .pt_XXL_sp,
  .pt_XXL {
    padding-top: 80px;
  }
  .pb_XS_sp,
  .pb_XS {
    padding-bottom: 10px;
  }
  .pb_SS_sp,
  .pb_SS {
    padding-bottom: 10px;
  }
  .pb_S_sp,
  .pb_S {
    padding-bottom: 20px;
  }
  .pb_M_sp,
  .pb_M {
    padding-bottom: 30px;
  }
  .pb_L_sp,
  .pb_L {
    padding-bottom: 40px;
  }
  .pb_LL_sp,
  .pb_LL {
    padding-bottom: 50px;
  }
  .pb_XL_sp,
  .pb_XL {
    padding-bottom: 60px;
  }
  .pb_XXL_sp,
  .pb_XXL {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 769px) {
  .ml_XS_pc, .ml_XS {
    margin-left: 15px;
  }
  .mt_XS_pc,
  .mt_XS,
  .editArea p,
  .editArea ul,
  .editArea ol {
    margin-top: 10px;
  }
  .mt_SS_pc,
  .mt_SS {
    margin-top: 20px;
  }
  .mt_S_pc,
  .mt_S,
  .list_ul,
  .list_ol,
  .editArea h4,
  .editArea h2 + p,
  .editArea img,
  .editArea table {
    margin-top: 30px;
  }
  .mt_M_pc,
  .mt_M,
  .editArea * + h2,
  .editArea h3 {
    margin-top: 40px;
  }
  .mt_L_pc,
  .mt_L {
    margin-top: 50px;
  }
  .mt_LL_pc,
  .mt_LL {
    margin-top: 60px;
  }
  .mt_XL_pc,
  .mt_XL {
    margin-top: 80px;
  }
  .mt_XXL_pc,
  .mt_XXL {
    margin-top: 100px;
  }
  .mb_XS_pc,
  .mb_XS {
    margin-bottom: 10px;
  }
  .mb_SS_pc,
  .mb_SS {
    margin-bottom: 20px;
  }
  .mb_S_pc,
  .mb_S {
    margin-bottom: 30px;
  }
  .mb_M_pc,
  .mb_M {
    margin-bottom: 40px;
  }
  .mb_L_pc,
  .mb_L {
    margin-bottom: 50px;
  }
  .mb_LL_pc,
  .mb_LL {
    margin-bottom: 60px;
  }
  .mb_XL_pc,
  .mb_XL {
    margin-bottom: 80px;
  }
  .mb_XXL_pc,
  .mb_XXL {
    margin-bottom: 100px;
  }
  .pt_XS_pc,
  .pt_XS {
    padding-top: 10px;
  }
  .pt_SS_pc,
  .pt_SS {
    padding-top: 20px;
  }
  .pt_S_pc,
  .pt_S {
    padding-top: 30px;
  }
  .pt_M_pc,
  .pt_M {
    padding-top: 40px;
  }
  .pt_L_pc,
  .pt_L {
    padding-top: 50px;
  }
  .pt_LL_pc,
  .pt_LL {
    padding-top: 60px;
  }
  .pt_XL_pc,
  .pt_XL {
    padding-top: 80px;
  }
  .pt_XXL_pc,
  .pt_XXL {
    padding-top: 100px;
  }
  .pb_XS_pc,
  .pb_XS {
    padding-bottom: 10px;
  }
  .pb_SS_pc,
  .pb_SS {
    padding-bottom: 20px;
  }
  .pb_S_pc,
  .pb_S {
    padding-bottom: 30px;
  }
  .pb_M_pc,
  .pb_M {
    padding-bottom: 40px;
  }
  .pb_L_pc,
  .pb_L {
    padding-bottom: 50px;
  }
  .pb_LL_pc,
  .pb_LL {
    padding-bottom: 60px;
  }
  .pb_XL_pc,
  .pb_XL {
    padding-bottom: 80px;
  }
  .pb_XXL_pc,
  .pb_XXL {
    padding-bottom: 100px;
  }
}

/*---------------------------------------------
フォントサイズの設定
---------------------------------------------*/
@media only screen and (max-width: 768px) {
  .font_XS_sp,
  .font_XS {
    font-size: 1rem;
  }
  .font_SS_sp,
  .font_SS {
    font-size: 1.2rem;
  }
  .font_S_sp, .tabBox .tabArea label p,
  .font_S {
    font-size: 1.4rem;
  }
  .font_M_sp,
  .font_M,
  .cardJob .area,
  .cardJob .jobList li,
  .card.column .contents,
  .cardInfo .cat,
  .cardInfo .contents .title,
  .tabBox .panelArea .tabPanel .tabBtn,
  .tabBox .panelArea .tabPanel .keyword input,
  .btn_more,
  .btn_blueL,
  .boxPickup .title,
  .condTxt,
  .sortTxt {
    font-size: 1.8rem;
  }
  .font_L_sp,
  .font_L,
  .h_2,
  .editArea h2,
  .h_faq,
  .tabBox .panelArea .tabPanel .tab_h,
  .pager {
    font-size: 2rem;
  }
  .font_LL_sp,
  .font_LL,
  .boxCatch {
    font-size: 2.2rem;
  }
  .font_XL_sp,
  .font_XL,
  .h_1 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 769px) {
  .font_XS_pc,
  .font_XS {
    font-size: 1.2rem;
  }
  .font_SS_pc,
  .font_SS {
    font-size: 1.4rem;
  }
  .font_S_pc,
  .font_S {
    font-size: 1.6rem;
  }
  .font_M_pc, .tabBox .panelArea .tabPanel .checkItem,
  .font_M,
  .cardJob .area,
  .cardJob .jobList li,
  .card.column .contents,
  .cardInfo .cat,
  .cardInfo .contents .title,
  .tabBox .panelArea .tabPanel .tabBtn,
  .tabBox .panelArea .tabPanel .keyword input,
  .btn_more,
  .btn_blueL,
  .boxPickup .title,
  .condTxt,
  .sortTxt {
    font-size: 1.8rem;
  }
  .font_L_pc, .tabBox .tabArea label p,
  .font_L,
  .h_2,
  .editArea h2,
  .h_faq,
  .tabBox .panelArea .tabPanel .tab_h,
  .pager {
    font-size: 2rem;
  }
  .font_LL_pc,
  .font_LL,
  .boxCatch {
    font-size: 2.6rem;
  }
  .font_XL_pc,
  .font_XL,
  .h_1 {
    font-size: 3.7rem;
  }
}

/*# sourceMappingURL=main.css.map */
/*----------------*/
/* 　全体レイアウト　　*/
/*----------------*/
html {
  font-size: 62.5%;
  font-family: "YoGo-Medium","游ゴシック体", "Yu Gothic", YuGothic, Arial,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Helvetica, sans-serif;
  color: #333;
  line-height: 1.8;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 769px) {
  html {
    min-height: 100%;
    position: relative;
  }
}

@media all and (-ms-high-contrast: none) {
  html {
    font-family: "メイリオ", Meiryo, Helvetica, sans-serif;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html:not(:target) {
    font-family: "メイリオ", Meiryo, Helvetica, sans-serif\9;
  }
}

body {
  font-size: 1.6rem;
  opacity: 1;
  transition: .2s .1s;
  position: absolute;
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
}

img {
  max-width: 100%;
  vertical-align: bottom;
  height: auto;
}

a {
  color: #333;
  text-decoration: none;
}

h1, h2, h3, h4 {
  line-height: 1.4;
}

p + p {
  margin-top: .2em;
}

@media only screen and (max-width: 768px) {
  .is-pc {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .is-sp {
    display: none !important;
  }
}

#ie {
  display: none;
}

/* コンテンツ幅 */
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 769px) {
  .container {
    max-width: 1230px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container.L {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 1030px) {
  .container.L {
    max-width: 1000px;
  }
}

.container.M {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 930px) {
  .container.M {
    max-width: 900px;
  }
}

.container.S {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 830px) {
  .container.S {
    max-width: 800px;
  }
}

/* テキスト位置 */
.t_left {
  text-align: left;
}

.t_ctr {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .t_ctr_sp, .condTxt, .sortBox {
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .t_ctr_pc {
    text-align: center;
  }
}

.t_right {
  text-align: right;
}

/* フォント系 */
.f_bold, .h_1, .h_2, .editArea h2, .h_3, .h_4, .h_faq, .cardJob .area, .cardJob .jobList li, .cardInfo .cat, .cardInfo .contents, .tabBox .tabArea label p, .tabBox .panelArea .tabPanel .checkItem, .tabBox .panelArea .tabPanel .tab_h, .navItem, .condBox, .sortTxt, .sitemap li.item {
  font-weight: 600;
}

.f_blue {
  color: #0F71BA;
}

.f_white {
  color: #fff;
}

/* 背景色 */
.bgc_white {
  background-color: #fff;
}

.bgc_cream {
  background-color: #FDFBEA;
}

.bgc_blue {
  background-color: #0F71BA;
}

.bgc_sky {
  background-color: #E3F0F8;
}

.bgc_gray {
  background-color: #f5f5f5;
}

/* ボーダー */
.bor_none {
  border: none;
}

/*--------------------------------
  グリッドレイアウト
--------------------------------*/
@media only screen and (max-width: 768px) {
  .grid {
    display: block;
  }
}

@media only screen and (min-width: 767px) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: -30px;
  }
  .gridItem {
    margin: 0 30px 30px 0;
  }
  .gridItem.x1 {
    width: calc(8.33333% - 30px);
  }
  .gridItem.x2 {
    width: calc(16.66666% - 30px);
  }
  .gridItem.x3 {
    width: calc(24.99999% - 30px);
  }
  .gridItem.x4 {
    width: calc(33.33332% - 30px);
  }
  .gridItem.x5 {
    width: calc(41.66665% - 30px);
  }
  .gridItem.x6 {
    width: calc(49.99998% - 30px);
  }
  .gridItem.x7 {
    width: calc(58.33331% - 30px);
  }
  .gridItem.x8 {
    width: calc(66.66664% - 30px);
  }
  .gridItem.x9 {
    width: calc(74.99997% - 30px);
  }
  .gridItem.x10 {
    width: calc(83.3333% - 30px);
  }
  .gridItem.x11 {
    width: calc(91.66663% - 30px);
  }
}

@media only screen and (max-width: 768px) {
  .gridJob {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .gridJob {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: -30px;
  }
  .gridJob .inner {
    margin: 0 30px 30px 0;
    width: calc(50% - 30px);
  }
}

@media only screen and (min-width: 1230px) {
  .gridJob {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: -30px;
  }
  .gridJob .inner {
    margin: 0 30px 30px 0;
  }
  .gridJob .inner.x1 {
    width: calc(8.33333% - 30px);
  }
  .gridJob .inner.x2 {
    width: calc(16.66666% - 30px);
  }
  .gridJob .inner.x3 {
    width: calc(24.99999% - 30px);
  }
  .gridJob .inner.x4 {
    width: calc(33.33332% - 30px);
  }
  .gridJob .inner.x5 {
    width: calc(41.66665% - 30px);
  }
  .gridJob .inner.x6 {
    width: calc(49.99998% - 30px);
  }
  .gridJob .inner.x7 {
    width: calc(58.33331% - 30px);
  }
  .gridJob .inner.x8 {
    width: calc(66.66664% - 30px);
  }
  .gridJob .inner.x9 {
    width: calc(74.99997% - 30px);
  }
  .gridJob .inner.x10 {
    width: calc(83.3333% - 30px);
  }
  .gridJob .inner.x11 {
    width: calc(91.66663% - 30px);
  }
}

/* sp グリッド維持で横スクロール */
.g_all {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-right: -30px;
}

.g_all .item {
  margin: 0 30px 30px 0;
}

.g_all .item.x1 {
  width: calc(8.33333% - 30px);
}

.g_all .item.x2 {
  width: calc(16.66666% - 30px);
}

.g_all .item.x3 {
  width: calc(24.99999% - 30px);
}

.g_all .item.x4 {
  width: calc(33.33332% - 30px);
}

.g_all .item.x5 {
  width: calc(41.66665% - 30px);
}

.g_all .item.x6 {
  width: calc(49.99998% - 30px);
}

.g_all .item.x7 {
  width: calc(58.33331% - 30px);
}

.g_all .item.x8 {
  width: calc(66.66664% - 30px);
}

.g_all .item.x9 {
  width: calc(74.99997% - 30px);
}

.g_all .item.x10 {
  width: calc(83.3333% - 30px);
}

.g_all .item.x11 {
  width: calc(91.66663% - 30px);
}

/* sp 2個並びグリッド */
@media only screen and (max-width: 768px) {
  .half_sp {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .half_sp .inner {
    width: calc(44% - $column-space);
  }
}

/*---------------------------------------------
// パンくずリスト
---------------------------------------------*/
#breadc li {
  display: inline-block;
  color: #7d7d7d;
}

#breadc li::after {
  font-family: 'FontAwesome';
  content: '\f105';
  line-height: 20px;
  font-size: 15px;
  padding: 0 10px;
}

#breadc li:last-child::after {
  content: none;
}

#breadc li a {
  text-decoration: none;
  color: #7d7d7d;
}

@media only screen and (min-width: 769px) {
  #breadc li a {
    transition: .3s;
  }
  #breadc li a:hover {
    color: #0F71BA;
  }
}

/*---------------------------------------------
//見出し
---------------------------------------------*/
.h_1 {
  color: #0F71BA;
  text-align: center;
}

.h_2, .editArea h2 {
  color: #0F71BA;
  background-color: #E3F0F8;
  padding: 15px;
  border-bottom: 3px solid #0F71BA;
}

.h_3 {
  color: #0F71BA;
  padding: 5px 0 5px 10px;
  border-left: 8px solid #0F71BA;
}

.h_4 {
  color: #0F71BA;
}

.h_faq {
  background-color: #FDFBEA;
  color: #0F71BA;
  padding: 20px;
}

/* リンク */
.link_a:not(.btn_more) {
  text-decoration: underline;
  color: #0F71BA;
}

.link_a:not(.btn_more)[target=_blank] {
  position: relative;
  padding-right: .7em;
  margin-right: .2em;
}

.link_a:not(.btn_more)[target=_blank]::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  content: '\f08e';
  font-family: 'fontAwesome';
}

@media only screen and (min-width: 769px) {
  .link_a:not(.btn_more) {
    transition: .3s;
  }
  .link_a:not(.btn_more):hover {
    opacity: .7;
  }
}

/*---------------------------------------------
// レイアウトパーツ
---------------------------------------------*/
/* テーブル */
.table {
  width: 100%;
}

.table thead th {
  background: #E3F0F8;
  font-weight: bold;
  color: #fff;
}

.table tbody {
  border-top: 1px solid #69B0E3;
}

.table tbody tr {
  border-bottom: 1px solid #69B0E3;
}

.table tbody th {
  font-weight: bold;
  background: #E3F0F8;
}

@media only screen and (max-width: 768px) {
  .table tbody th {
    border-bottom: 1px solid #69B0E3;
  }
}

@media only screen and (min-width: 769px) {
  .table tbody th {
    border-bottom: 1px solid #69B0E3;
    vertical-align: middle;
    width: 200px;
    padding-left: 30px;
  }
}

.table th, .table td {
  padding: .5em .8em;
}

@media only screen and (max-width: 768px) {
  .table th, .table td {
    width: 100%;
    display: block;
  }
}

/* カード（記事一覧）*/
@media only screen and (min-width: 769px) {
  .card article {
    transition: .5s;
  }
  .card article:hover {
    opacity: .9;
    box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

.card .cardBtn {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 100px);
}

.cardJob article {
  position: relative;
  border: 3px solid #0F71BA;
  padding: 60px 20px 90px;
}

.cardJob .area {
  color: #fff;
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: 2;
  padding-left: 20px;
  width: 150px;
  height: 45px;
  line-height: 45px;
  vertical-align: middle;
}

.cardJob .area.fukuoka {
  background-color: #372B6E;
}

.cardJob .area.kitakyusyu {
  background-color: #255198;
}

.cardJob .area.chikugo {
  background-color: #25A167;
}

.cardJob .area.chikuhou {
  background-color: #DE208C;
}

.cardJob .jobList li {
  padding-left: 40px;
  position: relative;
}

.cardJob .jobList li:not(:first-child) {
  margin-top: 5px;
}

.cardJob .jobList li::before {
  background-image: url("../images/icon/icon_job.png");
  background-repeat: no-repeat;
  background-size: 27px 108px;
  position: absolute;
  display: block;
  content: '';
  top: 2px;
  left: 0;
  width: 27px;
  height: 27px;
  line-height: 27px;
}

.cardJob .jobList li.icon_1::before {
  background-position: 0 0;
}

.cardJob .jobList li.icon_2::before {
  background-position: 0 -27px;
}

.cardJob .jobList li.icon_3::before {
  background-position: 0 -54px;
}

.cardJob .jobList li.icon_4::before {
  background-position: 0 -81px;
}

.card.column figure {
  border: 1px solid #acacac;
}

.card.column .contents {
  background-color: #0F71BA;
  color: #fff;
  min-height: 122px;
  padding: 15px;
}

.cardInfo .cat {
  color: #fff;
  padding: 5px 0;
  text-align: center;
}

.cardInfo .cat.event {
  background-color: #255198;
}

.cardInfo .cat.news {
  background-color: #23883E;
}

.cardInfo .contents {
  position: relative;
  border-left: 3px solid #0F71BA;
  border-right: 3px solid #0F71BA;
  border-bottom: 3px solid #0F71BA;
  background-color: #fff;
  padding: 15px;
  min-height: 250px;
}

@media only screen and (max-width: 768px) {
  .card.half {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .card.half article {
    width: calc(49.99998% - 8px);
  }
  .card.half article:nth-child(odd) {
    margin-right: 16px;
  }
}

/* タブ切り替え */
.tabBox {
  border: 2px solid #0F71BA;
  border-radius: 10px;
}

.tabBox input[type="radio"] {
  display: none;
}

.tabBox .tabArea {
  border-bottom: 5px solid #0F71BA;
  font-size: 0;
}

.tabBox .tabArea label {
  width: 50%;
  display: inline-block;
  padding: 12px 0 8px;
  background-color: #E3F0F8;
  text-align: center;
  cursor: pointer;
  transition: .7s;
}

.tabBox .tabArea label p {
  color: #0F71BA;
  margin-top: 5px;
}

.tabBox .tabArea .label_1 {
  border-radius: 10px 0 0 0 / 10px 0 0 0;
  border-right: 2px solid #0F71BA;
}

.tabBox .tabArea .label_2 {
  border-radius: 0 10px 0 0 / 0 10px 0 0;
}

.tabBox .panelArea {
  padding: 25px 15px;
  background-color: #fff;
  border-radius: 0 0 10px 10px / 0 0 10px 10px;
}

@media only screen and (min-width: 769px) {
  .tabBox .panelArea {
    padding: 50px 80px;
  }
}

.tabBox .panelArea .contents {
  display: none;
}

.tabBox .panelArea .tabPanel .tabCheck {
  margin-bottom: -20px;
}

.tabBox .panelArea .tabPanel .checkItem {
  color: #0F71BA;
  display: inline-block;
  margin-bottom: 20px;
}

.tabBox .panelArea .tabPanel .checkItem:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (min-width: 769px) {
  .tabBox .panelArea .tabPanel .checkItem:not(:last-child) {
    margin-right: 50px;
    min-width: 155px;
  }
}

.tabBox .panelArea .tabPanel .checkItem input[type="checkbox"] {
  display: none;
}

.tabBox .panelArea .tabPanel .checkItem input[type="checkbox"] + label {
  position: relative;
  padding-left: 35px;
}

.tabBox .panelArea .tabPanel .checkItem input[type="checkbox"] + label::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: 2px solid #0F71BA;
  border-radius: 3px;
  content: "";
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
}

.tabBox .panelArea .tabPanel .checkItem input[type="checkbox"]:checked + label::before {
  background: #0F71BA;
}

.tabBox .panelArea .tabPanel .checkItem input[type="checkbox"]:checked + label::after {
  content: "";
  display: block;
  width: 18px;
  height: 8px;
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  top: 30%;
  left: 7px;
  transform: rotate(-45deg) translateY(-50%);
}

.tabBox .panelArea .tabPanel .tabBtn {
  padding: 10px 25px;
  border: 1px solid #0F71BA;
  border-radius: 10px;
  background-color: #0F71BA;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  transition: .5s;
}

@media only screen and (min-width: 769px) {
  .tabBox .panelArea .tabPanel .tabBtn:hover {
    background-color: #fff;
    color: #0F71BA;
  }
}

.tabBox .panelArea .tabPanel .tab_h {
  color: #0F71BA;
  padding-left: 13px;
  border-left: 10px solid #0F71BA;
}

.tabBox .panelArea .tabPanel .tab_h + .tabCheck {
  margin-top: 15px;
  margin-left: 5px;
}

.tabBox .panelArea .tabPanel .keyword input {
  border: 2px solid #0F71BA;
  border-radius: 10px;
  color: #acacac;
  width: 100%;
  padding: 10px 20px;
}

@media only screen and (min-width: 769px) {
  .tabBox .panelArea .tabPanel .keyword input {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 750px;
  }
}

.tabBox #tab1:checked ~ .tabArea .label_1 {
  background: #fff;
}

.tabBox #tab1:checked ~ .panelArea #panel1 {
  display: block;
}

.tabBox #tab2:checked ~ .tabArea .label_2 {
  background: #fff;
}

.tabBox #tab2:checked ~ .panelArea #panel2 {
  display: block;
}

/* ボタン */
.btn_more {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  width: 270px;
  padding: 16px 10px;
  border: 3px solid #0F71BA;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.15);
}

.btn_more::after {
  right: 1em;
  top: 37%;
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #0F71BA;
  border-right: 2px solid #0F71BA;
  transform: rotate(45deg);
}

@media only screen and (min-width: 769px) {
  .btn_more {
    transition: .7s;
  }
  .btn_more:hover {
    background-color: #0F71BA;
    color: #fff;
  }
  .btn_more:hover::after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}

.btn_more.L {
  width: 100%;
}

@media only screen and (min-width: 769px) {
  .btn_more.L {
    max-width: 400px;
  }
}

.btn_blue {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
  max-width: 200px;
  width: 80%;
  padding: 6px;
  border: 1px solid #0F71BA;
  border-radius: 5px;
  background-color: #0F71BA;
  color: #fff;
  display: block;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  transition: .5s;
}

.btn_blue::after {
  right: 1em;
  top: 35%;
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
}

@media only screen and (min-width: 769px) {
  .btn_blue:hover {
    background-color: #fff;
    color: #0F71BA;
  }
  .btn_blue:hover::after {
    border-top: 2px solid #0F71BA;
    border-right: 2px solid #0F71BA;
  }
}

.btn_blueL {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 25px;
  border: 1px solid #0F71BA;
  border-radius: 10px;
  background-color: #0F71BA;
  color: #fff;
  display: block;
  max-width: 800px;
  width: 100%;
  text-align: center;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  transition: .5s;
}

@media only screen and (min-width: 769px) {
  .btn_blueL {
    width: 80%;
  }
  .btn_blueL:hover {
    background-color: #fff;
    color: #0F71BA;
  }
}

/* リスト */
.list_ul {
  margin: 10px 0;
}

.list_ul li {
  line-height: 2;
  text-indent: -1.5em;
  margin-left: 1.5em;
}

.list_ul li::before {
  display: inline-block;
  text-indent: 0;
  content: '●';
  color: #69B0E3;
  transform: scale(0.8, 0.8);
  line-height: 1;
  vertical-align: .08em;
  width: 1.5em;
}

.list_ul li a {
  color: #0F71BA;
  text-decoration: underline;
}

.list_ol {
  margin: 10px 0;
  counter-reset: num;
  text-indent: -1.7em;
}

.list_ol li {
  line-height: 2;
  margin-left: 1.7em;
}

.list_ol li::before {
  display: inline-block;
  text-indent: .43em;
  font-size: .8em;
  width: 1.4em;
  height: 1.3em;
  line-height: 1.6;
  counter-increment: num;
  content: counter(num);
  color: #fff;
  margin-right: .5em;
  background-color: #E3F0F8;
  border-radius: .7em;
}

.list_ol li a {
  color: #0F71BA;
  text-decoration: underline;
}

/* ボックス */
.boxPickup .title {
  border: 1px solid #D92020;
  background-color: #D92020;
  color: #fff;
  padding: 10px 20px;
}

.boxPickup .content {
  border-left: 3px solid #D92020;
  border-right: 3px solid #D92020;
  border-bottom: 3px solid #D92020;
  padding: 10px 20px;
}

.boxCatch {
  text-align: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  padding: .7em 10px;
  border-radius: 5px;
  letter-spacing: .1em;
}

/* バナー */
.bnrTop {
  text-align: center;
}

@media only screen and (min-width: 769px) {
  .bnrTop a {
    transition: .3s;
  }
  .bnrTop a:hover {
    opacity: .7;
  }
}

/* スクロール */
@media only screen and (max-width: 768px) {
  .scrollX {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .scrollX > * {
    min-width: 1230px;
  }
}

@media only screen and (max-width: 1170px) {
  .scrollX {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .scrollX > * {
    min-width: 1170px;
  }
}

/* map */
.iframeMap iframe {
  width: 100%;
}

/* ページネーション */
.pager {
  margin-top: 20px;
  text-align: center;
  color: #0F71BA;
}

.pager .pagerCnt {
  margin-left: 20px;
  margin-right: 14px;
  letter-spacing: .4em;
}

.pager .active, .pager .pagerArr {
  color: #0F71BA;
  font-size: 1.6em;
  padding: 0 10px;
  position: relative;
}

.pager .active {
  opacity: .5;
}

@media only screen and (min-width: 769px) {
  .pager .pagerArr .angle.left:hover::after {
    left: -5px;
  }
  .pager .pagerArr .angle.right:hover::after {
    right: -5px;
  }
}

.pager .angle::after {
  top: 40%;
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-top: 3px solid #0F71BA;
}

.pager .angle.left {
  display: inline-block;
}

.pager .angle.left::after {
  left: 0;
  border-left: 3px solid #0F71BA;
  transform: rotate(-45deg);
  transition: .5s;
}

.pager .angle.right {
  display: inline-block;
}

.pager .angle.right::after {
  right: 0;
  border-right: 3px solid #0F71BA;
  transform: rotate(45deg);
  transition: .5s;
}

/*---------------------------------------------
// 投稿エリア
---------------------------------------------*/
.thmb {
  text-align: center;
}

.editArea h2, .editArea h3, .editArea h4 {
  line-height: 1.8;
}

.editArea h3 {
  color: #0F71BA;
  padding: 0 0 0 10px;
  margin: 30px 0 20px;
  font-weight: 600;
  border-left: 8px solid #0F71BA;
}

.editArea h4 {
  color: #0F71BA;
  font-weight: 600;
}

.editArea p {
  line-height: 2;
}

.editArea ul li {
  line-height: 2;
}

.editArea ul li::before {
  display: inline-block;
  content: '●';
  color: #69B0E3;
  transform: scale(0.8, 0.8);
  line-height: 1;
  vertical-align: .08em;
  width: 1.5em;
  margin-right: .1em;
}

.editArea ul li a {
  color: #0F71BA;
  text-decoration: underline;
}

.editArea ol {
  counter-reset: num;
}

.editArea ol li {
  line-height: 2;
}

.editArea ol li::before {
  display: inline-block;
  text-indent: .3em;
  width: 1.4em;
  height: 1.3em;
  line-height: 1.6;
  counter-increment: num;
  content: counter(num);
  color: #69B0E3;
  margin-right: .1em;
}

.editArea ol li a {
  color: #0F71BA;
  text-decoration: underline;
}

.editArea a:not(.btn_more) {
  text-decoration: underline;
  color: #0F71BA;
}

.editArea a:not(.btn_more)[target=_blank] {
  position: relative;
  padding-right: .7em;
  margin-right: .2em;
}

.editArea a:not(.btn_more)[target=_blank]::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  content: '\f08e';
  font-family: 'fontAwesome';
}

@media only screen and (min-width: 769px) {
  .editArea a:not(.btn_more) {
    transition: .3s;
  }
  .editArea a:not(.btn_more):hover {
    opacity: .7;
  }
}

.editArea table {
  width: 100%;
  border-top: 1px solid #69B0E3;
}

@media only screen and (max-width: 768px) {
  .editArea table th, .editArea table td {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .editArea table th {
    width: 200px;
    vertical-align: middle;
  }
}

.editArea table th {
  font-weight: bold;
  background: #E3F0F8;
}

.editArea table th, .editArea table td {
  padding: .5em .8em;
  border-bottom: 1px solid #69B0E3;
}

.editArea strong {
  font-weight: 600;
}

/*---------------------------------------------
header
---------------------------------------------*/
.header {
  border-bottom: 2px solid rgba(15, 113, 186, 0.7);
  background-color: #fff;
}

.headerSub {
  text-align: center;
  padding-top: 10px;
}

@media only screen and (min-width: 769px) {
  .headerSub {
    text-align: right;
    padding-top: 15px;
    margin-right: 5px;
  }
}

.headerSub li {
  display: inline-block;
  vertical-align: middle;
}

.headerSub li a {
  transition: .5s;
}

.headerSub li.logoFu {
  margin-left: 25px;
}

@media only screen and (max-width: 768px) {
  .headerSub li.logoFu img {
    width: 90px;
    height: auto;
  }
}

@media only screen and (min-width: 769px) {
  .headerSub li.logoFu a:hover {
    opacity: .7;
  }
}

.headerSub li:not(.logoFu) {
  margin-top: 5px;
  margin-left: 10px;
}

@media only screen and (min-width: 769px) {
  .headerSub li:not(.logoFu) a:hover {
    color: #0F71BA;
  }
}

.headerSub li.border {
  position: relative;
  padding-left: 12px;
}

.headerSub li.border::before {
  content: '';
  height: 1em;
  line-height: 1em;
  width: 1px;
  position: absolute;
  left: 0;
  top: 20%;
  display: block;
  border-left: 1px solid #333;
}

.headerMain {
  position: relative;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .headerMain {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .headerMain .logo {
    width: 200px;
    height: auto;
  }
}

.headerMain .loginBtn {
  position: absolute;
  right: 130px;
  bottom: 0;
}

.headerMain .loginBtn a {
  padding: 8px 25px;
  border: 1px solid #0F71BA;
  border-radius: 5px;
  background-color: #0F71BA;
  color: #fff;
  display: block;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  transition: .5s;
}

@media only screen and (min-width: 769px) {
  .headerMain .loginBtn a:hover {
    background-color: #fff;
    color: #0F71BA;
  }
}

.nav {
  position: fixed;
  bottom: 100%;
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
  background-color: rgba(28, 28, 28, 0.7);
  transition: all .6s;
  z-index: 200;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  .nav {
    padding-top: 50px;
  }
}

.navBox {
  text-align: center;
}

.navList {
  padding-top: 10px;
  list-style: none;
}

.navItem {
  letter-spacing: .2em;
  text-align: center;
  padding: 10px;
  height: 50px;
  display: block;
  text-decoration: none;
  color: #fff;
  transition: .3s;
}

@media only screen and (max-width: 768px) {
  .navItem {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 769px) {
  .navItem {
    font-size: 2.4rem;
  }
  .navItem:hover {
    color: #E3F0F8;
  }
}

.navBtn {
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 300;
}

@media only screen and (max-width: 768px) {
  .navBtn {
    right: 5px;
    top: 0;
  }
}

@media only screen and (min-width: 769px) {
  .navBtn {
    right: 35px;
    top: 15px;
  }
}

.navLine {
  position: absolute;
  right: 0;
  width: 34px;
  height: 4px;
  background-color: #0F71BA;
  transition: all .6s;
}

@media only screen and (min-width: 769px) {
  .navLine {
    right: 15px;
  }
}

.navLine_1 {
  top: 10px;
}

.navLine_2 {
  top: 20px;
}

.navLine_3 {
  top: 30px;
}

/* 表示された時用のCSS */
.nav-open .nav {
  bottom: 0;
}

.nav-open .navLine {
  background-color: #fff;
}

.nav-open .navLine_1 {
  transform: rotate(45deg);
  top: 20px;
}

.nav-open .navLine_2 {
  width: 0;
  right: 50%;
}

.nav-open .navLine_3 {
  transform: rotate(-45deg);
  top: 20px;
}

.nav-open .navBtnFix {
  position: fixed;
  top: 74px;
  right: 20px;
}

@media only screen and (max-width: 768px) {
  .nav-open .navBtnFix {
    top: 20px;
  }
}

@media only screen and (min-width: 769px) {
  .nav-open .navBtnFix {
    right: 65px;
  }
}

@media only screen and (min-width: 1230px) {
  .nav-open .navBtnFix {
    right: calc(50% - 550px);
  }
}

/*---------------------------------------------
footer
---------------------------------------------*/
.footer {
  width: 100%;
  color: #fff;
  background-color: #0F71BA;
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 70px;
}

@media only screen and (min-width: 769px) {
  .footer {
    padding-top: 50px;
  }
}

.footer .toTop {
  position: absolute;
  padding: 10px;
  z-index: 20;
  right: 5px;
  bottom: 0;
  opacity: .8;
}

@media only screen and (min-width: 769px) {
  .footer .toTop {
    top: 40px;
    right: 10px;
    transition: .3s;
  }
  .footer .toTop:hover {
    top: 30px;
  }
}

@media only screen and (min-width: 1230px) {
  .footer .toTop {
    right: calc(50% - 585px);
  }
}

.footer .toTop img {
  display: block;
}

.footList {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .footList {
    margin-top: -20px;
  }
}

.footList li {
  display: inline-block;
}

.footList li:not(:first-child) {
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .footList li {
    margin-top: 20px;
  }
}

.footList li a {
  color: #fff;
}

@media only screen and (min-width: 769px) and (min-width: 769px) {
  .footList li a {
    transition: .3s;
  }
  .footList li a:hover {
    opacity: .7;
  }
}

@media only screen and (max-width: 768px) {
  .footBnr {
    margin-top: 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .footBnr {
    margin-top: 30px;
  }
}

.footBnr li {
  margin-top: 20px;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .footBnr li {
    width: 45%;
  }
  .footBnr li:nth-child(odd) {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 769px) {
  .footBnr li:not(:last-child) {
    margin-right: 15px;
  }
  .footBnr li a {
    display: block;
    transition: .5s;
  }
  .footBnr li a:hover {
    opacity: .8;
  }
}

/*---------------------------------------------
トップページ
---------------------------------------------*/
/* トップスライド */
.slide {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .slide {
    padding: 100% 0 0;
  }
}

@media only screen and (min-width: 769px) {
  .slide {
    padding: 32.15% 0 0;
  }
}

.slideWrap {
  width: 100%;
}

@media only screen and (min-width: 769px) {
  .slideWrap {
    max-width: 1230px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.slide button {
  border: none;
  background: none;
}

.slideBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slideItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: 3s;
}

.slideItem img {
  width: 100%;
}

.slideItem.is-show {
  opacity: 1;
  z-index: 10;
  transition: 3s;
}

.slideItem:hover {
  opacity: .8;
}

.slideCtl {
  z-index: 10;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 10px;
}

.slideCtl li {
  padding: 0 20px;
  display: inline-block;
}

.slideCtl li button {
  color: #fff;
  text-shadow: 1px 1px 1px #999;
}

.slideCtl li .current {
  color: #969696;
}

/*---------------------------------------------
求人一覧
---------------------------------------------*/
/* 検索条件 */
.condBox {
  padding: 15px;
}

@media only screen and (min-width: 769px) {
  .condBox {
    padding: 30px;
  }
}

.condList li {
  display: inline-block;
  position: relative;
}

.condList li:not(:last-child) {
  padding-right: 1em;
  margin-right: 5px;
}

.condList li:not(:last-child)::after {
  position: absolute;
  top: 0;
  right: 0;
  content: '、';
  display: block;
}

/* 並び替え */
@media only screen and (min-width: 769px) {
  .sortBox > * {
    display: inline-block;
  }
}

@media only screen and (min-width: 769px) {
  .sortList {
    margin-left: 20px;
  }
}

.sortList li {
  display: inline-block;
  margin-top: 15px;
  width: 128px;
  text-align: center;
  margin-right: 10px;
}

.sortList li a {
  display: block;
  border: 1px solid #7d7d7d;
  border-radius: 15px;
  background-color: #fff;
}

@media only screen and (min-width: 769px) {
  .sortList li a {
    transition: .5s;
  }
  .sortList li a:hover {
    border: 1px solid #0F71BA;
    background-color: #0F71BA;
    color: #fff;
  }
}

.sortList li a.current {
  border: 1px solid #0F71BA;
  background-color: #0F71BA;
  color: #fff;
}

/*---------------------------------------------
サイトマップ
---------------------------------------------*/
.sitemap a {
  color: #0F71BA;
  text-decoration: underline;
}

.sitemap li.item .itemList li::before {
  display: inline-block;
  content: 'ー';
  color: #69B0E3;
  transform: scale(0.8, 0.8);
  line-height: 1;
  vertical-align: .08em;
  width: 1.5em;
  margin-right: .1em;
}

/*# sourceMappingURL=main.css.map */
