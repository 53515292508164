
/*---------------------------------------------
トップページ
---------------------------------------------*/
/* トップスライド */
.slide{
  position: relative;
  width: 100%;
  @media only screen and (max-width:$spwidth) {
    padding: 100% 0 0;
  }
  @media only screen and (min-width:$tbwidth) {
    padding: 32.15% 0 0;
  }//pc
  &Wrap{
    width: 100%;
    @media only screen and (min-width:$tbwidth) {
      max-width: $pcwidth;
      padding-left: $column-space;
      padding-right: $column-space;
      @include margin-auto;
    }
  }
  button{
    border:none;
    background: none;
  }
  &Box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &Item{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    img{
      width: 100%;
    }
    opacity: 0;
    transition: 3s;
    &.is-show{
      opacity: 1;
      z-index: 10;
      transition: 3s;
    }
    &:hover{
      opacity: .8;
    }
  }//&_item
  &Ctl{
    z-index: 10;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 10px;
    li{
      padding: 0 20px;
      display: inline-block;
      button{
        color: #fff;
        text-shadow: 1px 1px 1px #999;
      }
      .current{
        color: #969696;
      }
    }
  }//&Ctl
}//.slide

/*---------------------------------------------
求人一覧
---------------------------------------------*/
/* 検索条件 */
.cond{
  &Box{
    padding: 15px;
    @media only screen and (min-width:$tbwidth) {
      padding: 30px;
    }//tb
    @extend .f_bold;
  }
  &Txt{
    @extend .font_M;
    @extend .t_ctr_sp;
  }
  &List{
    li{
      display: inline-block;
      position: relative;
      &:not(:last-child){
        padding-right: 1em;
        margin-right: 5px;
        &::after{
          position: absolute;
          top: 0;
          right: 0;
          content: '、';
          display: block;
        }
      }//&:not(:last-child)
    }//li
  }//&List
}//.cond
/* 並び替え */
.sort{
  &Box{
    @extend .t_ctr_sp;
    @media only screen and (min-width:$tbwidth) {
      > * {
        display: inline-block;
      }
    }//tb
  }//&Box
  &Txt{
    @extend .font_M;
    @extend .f_bold;

  }
  &List{
    @media only screen and (min-width:$tbwidth) {
      margin-left: 20px;
    }
    li{
      display: inline-block;
      margin-top: 15px;
      width: 128px;
      text-align: center;
      margin-right: 10px;
      a{
        display: block;
        border: 1px solid $c-gray;
        border-radius: 15px;
        background-color: $c-white;
        @media only screen and (min-width:$tbwidth) {
          transition: .5s;
          &:hover{
            border: 1px solid $c-main;
            background-color: $c-main;
            color: $c-white;
          }
        }//tb
        &.current{
          border: 1px solid $c-main;
          background-color: $c-main;
          color: $c-white;
        }
      }//a
    }//li
  }//&List
}//.sort

/*---------------------------------------------
サイトマップ
---------------------------------------------*/
.sitemap{
  a{
    color: $c-main;
    text-decoration: underline;
  }
  li.item{
    @extend .f_bold;
    .itemList{
      li{
        // padding-left: 20px;
        &::before{
          display: inline-block;
          content: 'ー';
          color: $c-border;
          transform: scale(.8,.8);
          line-height: 1;
          vertical-align: .08em;
          width: 1.5em;
          margin-right: .1em;
        }

      }
    }//.itemList
  }//.item
}//.sitemap

/*# sourceMappingURL=main.css.map */
