/*----------------*/
/* 　全体レイアウト　　*/
/*----------------*/
html {
  font-size: 62.5%;
  font-family: "YoGo-Medium","游ゴシック体", "Yu Gothic", YuGothic, Arial,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Helvetica, sans-serif;
  color: $c-font;
  line-height: 1.8;
  position: relative;
  height: 100%;
  @media only screen and (min-width: $tbwidth) {
    min-height: 100%;
    position: relative;
  }
  @media all and (-ms-high-contrast:none) {
    font-family:"メイリオ", Meiryo, Helvetica, sans-serif;
  }
  &:not(:target) {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-family:"メイリオ", Meiryo, Helvetica, sans-serif\9;
    }
  }
}
body {
  font-size: 1.6rem;
  opacity: 1;
  transition: .2s .1s;
  position: absolute;
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
}
img{
  max-width:100%;
  vertical-align: bottom;
  height: auto;
}
a{
  color: $c-font;
  text-decoration: none;
}
h1,h2,h3,h4{
  line-height: 1.4;
}
p + p{
  margin-top: .2em;
}
.is-pc{
  @media only screen and (max-width:$spwidth) {
    display: none!important;
  }
}
.is-sp{
  @media only screen and (min-width:$tbwidth) {
    display: none!important;
  }
}

#ie {
  display: none;
}

/* コンテンツ幅 */
.container{
  @include margin-auto;
  width:100%;
  @media only screen and (max-width: $spwidth) {
    padding-left: $unit;
    padding-right: $unit;
  }//sp
  @media only screen and (min-width: $tbwidth) {
    max-width: $pcwidth;
    padding-left: $column-space;
    padding-right: $column-space;
  }//pc
  &.L{
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: $container_L + 30) {
      max-width: $container_L;
    }
  }//.S
  &.M{
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: $container_M + 30) {
      max-width: $container_M;
    }
  }//.S
  &.S{
    padding-left: 0;
    padding-right: 0;
    @media only screen and (min-width: $container_S + 30) {
      max-width: $container_S;
    }
  }//.S
}//.container

/* テキスト位置 */
.t{
  &_left {
    text-align: left;
  }
  &_ctr {
    text-align: center;
    &_sp{
      @media only screen and (max-width:$spwidth) {
        text-align: center;
      }
    }//&_sp
    &_pc{
      @media only screen and (min-width: $tbwidth) {
        text-align: center;
      }
    }//&_pc
  }//&_ctr
  &_right {
    text-align: right;
  }
}//.t

/* フォント系 */
.f {
  &_bold {
    font-weight: 600;
  }
  &_blue{
    color: $c-main;
  }
  &_white{
    color: $c-white;
  }
}//.f

/* 背景色 */
.bgc{
  &_white{
    background-color: $c-white;
  }
  &_cream{
    background-color: $c-back;
  }
  &_blue{
    background-color: $c-main;
  }
  &_sky{
    background-color: $c-sub;
  }
  &_gray{
    background-color: $c-offwhite;
  }
}//.bgc

/* ボーダー */
.bor{
  &_none {
    border: none;
  }
}

/*--------------------------------
  グリッドレイアウト
--------------------------------*/
.grid {
  @media only screen and (max-width:$spwidth) {
    display: block;
  }//sp
  @media only screen and (min-width:$spwidth - 1) {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: -$column-space;
    &Item{
      margin: 0 $column-space $column-space 0;
      @for $i from 1 through 11 {
        &.x#{$i} {
          $grid-width: 8.33333% * $i;
          width: calc(#{$grid-width} - #{ $column-space} );
        }
      }
    }//&_item
  }//tb
  &Job{
    @media only screen and (max-width:$spwidth) {
      display: block;
    }//sp
    @media only screen and (min-width: $tbwidth) {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin-right: -$column-space;
      .inner{
        margin: 0 $column-space $column-space 0;
        width: calc(50% - #{$column-space}) ;
        // width: calc(50% - 30px);
      }
    }//tb
    @media only screen and (min-width:$pcwidth) {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin-right: -$column-space;
      .inner{
        margin: 0 $column-space $column-space 0;
        @for $i from 1 through 11 {
          &.x#{$i} {
            $grid-width: 8.33333% * $i;
            width: calc(#{$grid-width} - #{ $column-space} );
          }
        }
      }//&_item
    }//tb
  }//&Job
}//.grid

/* sp グリッド維持で横スクロール */
.g_all {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-right: -$column-space;
  .item{
    margin: 0 $column-space $column-space 0;
    @for $i from 1 through 11 {
      &.x#{$i} {
        $grid-width: 8.33333% * $i;
        width: calc(#{$grid-width} - #{ $column-space} );
      }
    }
  }//&_item
}//.g_All

/* sp 2個並びグリッド */
.half_sp{
  @media only screen and (max-width:$spwidth) {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .inner{
      width: calc(44% - $column-space);
    }
  }//sp
}//.half_sp

/*---------------------------------------------
// パンくずリスト
---------------------------------------------*/
#breadc{
  li{
    display: inline-block;
    color: $c-gray;
    &::after{
      font-family: 'FontAwesome';
      content: '\f105';
      line-height: 20px;
      font-size: 15px;
      padding: 0 10px;
    }
    &:last-child::after{
      content: none;
    }
    a{
      text-decoration: none;
      color: $c-gray;
      @media only screen and (min-width: $tbwidth) {
        transition: .3s;
        &:hover {
          color: $c-main;
        }
      }//pc
    }//a
  }//li
}//#breadcrumb

/*---------------------------------------------
//見出し
---------------------------------------------*/
.h_1{
  @extend .font_XL;
  @extend .f_bold;
  color: $c-main;
  text-align: center;
}
.h_2{
  @extend .font_L;
  @extend .f_bold;
  color: $c-main;
  background-color: $c-sub;
  padding: 15px;
  border-bottom: 3px solid $c-main;
}
.h_3{
  @extend .f_bold;
  color: $c-main;
  padding:5px 0 5px 10px;
  border-left: 8px solid $c-main;
}
.h_4{
  @extend .f_bold;
  color: $c-main;
}
.h_faq{
  @extend .font_L;
  @extend .f_bold;
  background-color: $c-back;
  color: $c-main;
  padding: 20px;
}

/* リンク */
.link_a{
  &:not(.btn_more){
    text-decoration: underline;
    color: $c-main;
    &[target=_blank]{
      position: relative;
      padding-right: .7em;
      margin-right: .2em;
      &::after{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1rem;
        content: '\f08e';
        font-family: 'fontAwesome';
      }
    }
    @include hover ;
  }//&:not(.btn_more)
}//.link_a

/*---------------------------------------------
// レイアウトパーツ
---------------------------------------------*/
/* テーブル */
.table{
  width: 100%;
  thead{
    th{
      background: $c-sub;
      font-weight: bold;
      color: #fff;
    }
  }
  tbody{
    border-top: 1px solid $c-border;
    tr{
      border-bottom: 1px solid $c-border;
    }
    th{
      font-weight: bold;
      background: $c-sub;
      @media only screen and (max-width:$spwidth) {
        border-bottom: 1px solid $c-border;
      }//sp
      @media only screen and (min-width:$tbwidth) {
        border-bottom: 1px solid $c-border;
        vertical-align: middle;
        width: 200px;
        padding-left: 30px;
      }//tb
    }//th
  }//tbody
  th,td{
    padding: .5em .8em;
    @media only screen and (max-width:$spwidth) {
      width: 100%;
      display: block;
    }
  }
}//table

/* カード（記事一覧）*/
.card{
  article{
    @media only screen and (min-width:$tbwidth) {
      transition: .5s;
      &:hover{
        opacity: .9;
        box-shadow: 8px 8px 8px 0 rgba(0,0,0,0.15);
      }
    }
  }//article
  .cardBtn{
    position: absolute;
    bottom: 30px;
    left:calc(50% - 100px;)
  }//.cardBtn
  &Job{
    article{
      position: relative;
      border: 3px solid $c-main;
      padding: 60px 20px 90px;
    }
    .area{
      @extend .font_M;
      @extend .f_bold;
      color: $c-white;
      position: absolute;
      top: -3px;
      left: -3px;
      z-index: 2;
      padding-left: 20px;
      width:150px;
      height: 45px;
      line-height: 45px;
      vertical-align: middle;
      &.fukuoka{
        background-color: $c-fukuoka;
      }
      &.kitakyusyu{
        background-color: $c-kitakyusyu;
      }
      &.chikugo{
        background-color: $c-chikugo;
      }
      &.chikuhou{
        background-color: $c-chikuhou;
      }
    }//.area
    .jobList{
      li{
        @extend .font_M;
        @extend .f_bold;
        padding-left: 40px;
        position: relative;
        &:not(:first-child){
          margin-top: 5px;
        }
        &::before{
          background-image: url("../images/icon/icon_job.png");
          background-repeat: no-repeat;
          background-size: 27px 108px;
          position: absolute;
          display: block;
          content: '';
          top: 2px;
          left: 0;
          width: 27px;
          height: 27px;
          line-height: 27px;
        }//&::before
        &.icon{
          &_1{
            &::before{
              background-position: 0 0;
            }
          }
          &_2{
            &::before{
              background-position: 0 -27px;
            }
          }
          &_3{
            &::before{
              background-position: 0 -54px;
            }
          }
          &_4{
            &::before{
              background-position: 0 -81px;
            }
          }
        }//&.icon
      }//li
    }//.jobList
  }//&Job
  &.column{
    figure{
      border: 1px solid $c-lGray;
    }
    .contents{
      @extend .font_M;
      background-color: $c-main;
      color: $c-white;
      min-height: 122px;
      padding: 15px;

    }
  }//&.column
  &Info{
    .cat{
      color: $c-white;
      @extend .font_M;
      @extend .f_bold;
      padding: 5px 0;
      text-align: center;
      &.event{
        background-color: $c-event;
      }
      &.news{
        background-color: $c-news;
      }
    }//.cat
    .contents{
      position: relative;
      @extend .f_bold;
      border-left: 3px solid $c-main;
      border-right: 3px solid $c-main;
      border-bottom: 3px solid $c-main;
      background-color: $c-white;
      padding: 15px;
      min-height: 250px;
      .title{
        @extend .font_M;
      }
    }//.contents
  }//&Info
  &.half{
    @media only screen and (max-width:$spwidth) {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      article{
        width: calc(49.99998% - 8px);
        &:nth-child(odd){
          margin-right: 16px;
        }
      }
    }//sp
  }//&.half
}//.card

/* タブ切り替え */
.tab{
  &Box{
    border: 2px solid $c-main;
    border-radius: 10px;
    input[type="radio"]{
      display: none;
    }
    .tabArea{
      border-bottom: 5px solid $c-main;
      font-size:0;
      label{
        width: 50%;
        display:inline-block;
        padding:12px 0 8px;
        background-color: $c-sub;
        text-align:center;
        cursor: pointer;
        transition: .7s;
        p{
          @extend .font_S_sp;
          @extend .font_L_pc;
          @extend .f_bold;
          color: $c-main;
          margin-top: 5px;
        }
      }//label
      .label_1{
        border-radius: 10px 0 0 0 / 10px 0 0 0;
        border-right: 2px solid $c-main;
      }
      .label_2{
        border-radius: 0 10px 0 0 / 0 10px 0 0;
      }
    }//.tabArea
    .panelArea {
      padding: 25px 15px;
      background-color: $c-white;
      border-radius: 0 0 10px 10px / 0 0 10px 10px;
      @media only screen and (min-width:$tbwidth) {
        padding: 50px 80px;
      }//tb
      .contents{
        display:none;
      }
      .tabPanel{
        .tabCheck{
          margin-bottom: -20px;
        }
        .checkItem{
          color: $c-main;
          @extend .font_M_pc;
          @extend .f_bold;
          display: inline-block;
          margin-bottom: 20px;
          &:not(:last-child){
            margin-right: 30px;
            @media only screen and (min-width:$tbwidth) {
              margin-right: 50px;
              min-width: 155px;
            }
          }
          input[type="checkbox"] {
            display: none;
            + label {
              position: relative;
              padding-left: 35px;
              &::before{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                border: 2px solid $c-main;
                border-radius: 3px;
                content: "";
                cursor: pointer;
                display: block;
                width: 20px;
                height: 20px;
              }
            }//+ label
            &:checked+label{
              &::before {
                background: $c-main;
              }
              &::after {
                content: "";
                display: block;
                width: 18px;
                height: 8px;
                box-sizing: border-box;
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                position: absolute;
                top: 30%;
                left: 7px;
                transform: rotate(-45deg) translateY(-50%);
              }
            }//&:checked+label
          }//input[type="checkbox"]
        }//.checkItem
        .tabBtn{
          @extend .font_M;
          padding: 10px 25px;
          border: 1px solid $c-main;
          border-radius: 10px;
          background-color: $c-main;
          color: $c-white;
          display: block;
          width: 100%;
          text-align: center;
          box-shadow: 0 3px 3px 0 rgba(0,0,0,0.15);
          transition: .5s;
          @media only screen and (min-width:$tbwidth) {
            &:hover{
              background-color: $c-white;
              color: $c-main;
            }
          }//tb
        }//.tabBtn
        .tab_h{
          @extend .font_L;
          @extend .f_bold;
          color: $c-main;
          padding-left: 13px;
          border-left: 10px solid $c-main;
           + .tabCheck{
            margin-top: 15px;
            margin-left: 5px;
          }
        }//.tab_h
        .keyword{
          input{
            @extend .font_M;
            border: 2px solid $c-main;
            border-radius: 10px;
            color: $c-lGray;
            width: 100%;
            @media only screen and (min-width:$tbwidth) {
              @include margin-auto;
              display: block;
              max-width: 750px;

            }
            padding: 10px 20px;
          }
        }
      }//.tabPanel
    }//.panelArea
    #tab1:checked ~ .tabArea .label_1{
      background:#fff;
    }
    #tab1:checked ~ .panelArea #panel1{
      display:block;
    }
    #tab2:checked ~ .tabArea .label_2{
      background:#fff;
    }
    #tab2:checked ~ .panelArea #panel2{
      display:block;
    }
  }//&Box
}//.tab

/* ボタン */
.btn{
  &_more{
    @extend .font_M;
    @include margin-auto;
    display: block;
    text-align: center;
    width: 270px;
    padding: 16px 10px;
    border: 3px solid $c-main;
    background-color: $c-white;
    position: relative;
    box-shadow: 0px 6px 3px -3px rgba(0,0,0,0.15);
    &::after{
      right: 1em;
      top: 37%;
      position: absolute;
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-top: 2px solid $c-main;
      border-right: 2px solid $c-main;
      transform: rotate(45deg);
    }
    @media only screen and (min-width:$tbwidth) {
      transition: .7s;
      &:hover{
        background-color: $c-main;
        color: $c-white;
          &::after{
          border-top: 2px solid $c-white;
          border-right: 2px solid $c-white;
        }
      }
    }//pc
    &.L{
      width: 100%;
      @media only screen and (min-width:$tbwidth) {
        max-width: 400px;
      }
    }
  }//&_more
  &_blue{
    @include margin-auto;
    position: relative;
    text-align: center;
    max-width: 200px;
    width: 80%;
    padding: 6px;
    border: 1px solid $c-main;
    border-radius: 5px;
    background-color: $c-main;
    color: $c-white;
    display: block;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.15);
    transition: .5s;
    &::after{
      right: 1em;
      top: 35%;
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-top: 2px solid $c-white;
      border-right: 2px solid $c-white;
      transform: rotate(45deg);
    }
    @media only screen and (min-width:$tbwidth) {
      &:hover{
        background-color: $c-white;
        color: $c-main;
        &::after{
          border-top: 2px solid $c-main;
          border-right: 2px solid $c-main;
        }
      }
    }//pc
  }//.&_blue
  &_blueL{
    @include margin-auto;
    @extend .font_M;
    padding: 10px 25px;
    border: 1px solid $c-main;
    border-radius: 10px;
    background-color: $c-main;
    color: $c-white;
    display: block;
    max-width: 800px;
    width: 100%;
    text-align: center;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.15);
    transition: .5s;
    @media only screen and (min-width:$tbwidth) {
      width: 80%;
      &:hover{
        background-color: $c-white;
        color: $c-main;
      }
    }//tb
  }//.tabBtn
}//.btn

/* リスト */
.list{
  &_ul{
    margin: 10px 0;
    @extend .mt_S;
    li{
      line-height: 2;
      text-indent: -1.5em;
      margin-left: 1.5em;
      &::before{
        display: inline-block;
        text-indent: 0;
        content: '●';
        color: $c-border;
        transform: scale(.8,.8);
        line-height: 1;
        vertical-align: .08em;
        width: 1.5em;
        }
      a{
        color: $c-main;
        text-decoration: underline;
      }
    }
  }//_ul
  &_ol{
    margin: 10px 0;
    counter-reset: num;
    text-indent: -1.7em;
    @extend .mt_S;
    li{
      line-height: 2;
      margin-left: 1.7em;
      &::before{
        display: inline-block;
        text-indent: .43em;
        font-size: .8em;
        width: 1.4em;
        height: 1.3em;
        line-height: 1.6;
        counter-increment: num;
        content: counter(num);
        color: $c-white;
        margin-right: .5em;
        background-color: $c-sub;
        border-radius: .7em;
      }
      a{
        color: $c-main;
        text-decoration: underline;
      }
    }
  }//ol
}//.list

/* ボックス */
.box{
  &Pickup{
    .title{
      @extend .font_M;
      border: 1px solid $c-red;
      background-color: $c-red;
      color: $c-white;
      padding: 10px 20px;
    }
    .content{
      border-left: 3px solid $c-red;
      border-right: 3px solid $c-red;
      border-bottom: 3px solid $c-red;
      padding: 10px 20px;
    }
  }//&Pickup
  &Catch{
    @extend .font_LL;
    text-align: center;
    box-shadow: 0 0 8px rgba(0,0,0,.4);
    padding: .7em 10px ;
    border-radius: 5px;
    letter-spacing: .1em;
  }//&Catch
}//.box

/* バナー */
.bnr{
  &Top{
    text-align: center;
    a{
      @include hover;
    }
  }//&Top
}//.bnr

/* スクロール */
.scrollX {
  @media only screen and (max-width:$spwidth) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    > * {
      min-width: $pcwidth;
    }
  }//sp
  @media only screen and (max-width:$pcwidth - ($column-space * 2)) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    > * {
      min-width: $pcwidth - ($column-space * 2);
    }
  }
}//.scrollX

/* map */
.iframeMap{
  iframe{
    width: 100%;
  }
}

/* ページネーション */
.pager{
  margin-top: 20px;
  text-align: center;
  color: $c-main;
  @extend .font_L;
  .pagerCnt{
    margin-left: 20px;
    margin-right: 14px;
    letter-spacing: .4em;
  }
  .active,.pagerArr{
    color: $c-main;
    font-size: 1.6em;
    padding: 0 10px;
    position: relative;
  }
  .active{
    opacity: .5;
  }
  .pagerArr{
    @media only screen and (min-width:$tbwidth) {
      .angle{
        &.left{
          &:hover{
            &::after{
              left: -5px;
            }
          }
        }//&.left
        &.right{
          &:hover{
            &::after{
              right: -5px;
            }
          }
        }//&.right
      }//.angle
    }//tb
  }//.pagerArr
  .angle{
    &::after{
      top: 40%;
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-top: 3px solid $c-main;
    }
    &.left{
      display: inline-block;
      &::after{
        left: 0;
        border-left: 3px solid $c-main;
        transform: rotate(-45deg);
        transition: .5s;
      }//&::after
    }//&.left
    &.right{
      display: inline-block;
      &::after{
        right: 0;
        border-right: 3px solid $c-main;
        transform: rotate(45deg);
        transition: .5s;
      }//&::after
    }//&.right
  }//.angle
}//.pager

/*---------------------------------------------
// 投稿エリア
---------------------------------------------*/
.thmb{
  text-align: center;
}
.editArea{
  h2,h3,h4{
    line-height: 1.8;
  }
  h2{
    @extend .h_2;
  }
  * + h2{
    @extend .mt_M;
  }
  h3{
    @extend .mt_M;
    color: $c-main;
    padding:0 0 0 10px;
    margin: 30px 0 20px;
    font-weight: 600;
    border-left: 8px solid $c-main;
  }
  h4{
    @extend .mt_S;
    color: $c-main;
    font-weight: 600;
  }
  p{
    @extend .mt_XS;
    line-height: 2;
  }
  h2 + p{
    @extend .mt_S;
  }
  img{
    @extend .mt_S;
  }
  ul{
    @extend .mt_XS;
    li{
    line-height: 2;
    &::before{
      display: inline-block;
      content: '●';
      color: $c-border;
      transform: scale(.8,.8);
      line-height: 1;
      vertical-align: .08em;
      width: 1.5em;
      margin-right: .1em;
      }
      a{
        color: $c-main;
        text-decoration: underline;
      }
    }
  }//ul
  ol{
    @extend .mt_XS;
    counter-reset: num;
    li{
      line-height: 2;
      &::before{
        display: inline-block;
        text-indent: .3em;
        width: 1.4em;
        height: 1.3em;
        line-height: 1.6;
        counter-increment: num;
        content: counter(num);
        color: $c-border;
        margin-right: .1em;
      }
      a{
        color: $c-main;
        text-decoration: underline;
      }
    }//li
  }//ol
  a:not(.btn_more){
    text-decoration: underline;
    color: $c-main;
    &[target=_blank]{
      position: relative;
      padding-right: .7em;
      margin-right: .2em;
      &::after{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1rem;
        content: '\f08e';
        font-family: 'fontAwesome';
      }
    }//&[target=_blank]
    @include hover ;
  }//a:not(.btn_more)
  table{
    @extend .mt_S;
    width: 100%;
    border-top: 1px solid $c-border;
    @media only screen and (max-width:$spwidth) {
      th, td{
        display: block;
      }
    }//sp
    @media only screen and (min-width:$tbwidth) {
      th{
        width: 200px;
        vertical-align: middle;
      }
    }//tb
    th{
      font-weight: bold;
      background: $c-sub;
    }
    th,td{
      padding: .5em .8em;
      border-bottom: 1px solid $c-border;
    }
  }//table
  strong{
    font-weight: 600;
  }
}//.editArea
