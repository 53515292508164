// フォントカラー
$c-font : #333;
// メインカラー
$c-main : #0F71BA;
// サブカラー
$c-sub : #E3F0F8;
// バックカラー
$c-back : #FDFBEA;
// ボーダーカラー
$c-border : #69B0E3;
// メニューカラー
$c-menu : #1C1C1C;

// 単色
$c-fukuoka :#372B6E;
$c-kitakyusyu : #255198;
$c-chikugo: #25A167;
$c-chikuhou: #DE208C;
$c-event: #255198;
$c-news: #23883E;
$c-white: #fff;
$c-offwhite: #f5f5f5;
$c-gray: #7d7d7d;
$c-lGray: #acacac;
$c-red: #D92020;

//メディアクエリサイズ
$spwidth :768px;
$tbwidth :769px;
$pcwidth :1230px;

$unit: 15px;
$column-space: $unit * 2;
$container_L: 1000px;
$container_M: 900px;
$container_S: 800px;


@mixin grid($margin) {
  .grid{
    @media only screen and (min-width:$tbwidth) {
      display: flex;
      flex-wrap: wrap;
      margin-left: - $margin px;
      margin-right: - $margin px;
      max-width: calc(100% + ($margin * 2));

      .grid_item{
        @for $i from 1 through 11 {
          $w : 8.333%;
          &.x#{$i} {
            width:calc(#{$i * $w} - ($margin * 2) px);
            margin: 0 $margin px;
          }
        }
      }
    }//pc
  }//.grid
}

$names: "XS", "SS", "S", "M", "L", "LL", "XL";
@media only screen and (max-width:$spwidth) {
  $sizes:( XS:10px,SS:10px, S:20px, M:30px, L:40px, LL:50px, XL:60px, XXL:80px);
  @each $names, $size in $sizes {
    .mt_#{$names}_sp,
    .mt_#{$names} {
      margin-top: $size;
    }
  }
  @each $names, $size in $sizes {
    .mb_#{$names}_sp,
    .mb_#{$names} {
      margin-bottom: $size;
    }
  }
  @each $names, $size in $sizes {
    .pt_#{$names}_sp,
    .pt_#{$names} {
      padding-top: $size;
    }
  }
  @each $names, $size in $sizes {
    .pb_#{$names}_sp,
    .pb_#{$names} {
      padding-bottom: $size;
    }
  }
}//sp

@media only screen and (min-width:$tbwidth) {
  .ml{
    &_XS_pc,
    &_XS{
      margin-left: 15px;
    }
  }//md-margine-left
  $sizes:( XS:10px,SS:20px, S:30px, M:40px, L:50px, LL:60px, XL:80px, XXL:100px);
  @each $names, $size in $sizes {
    .mt_#{$names}_pc,
    .mt_#{$names} {
      margin-top: $size;
    }
  }
  @each $names, $size in $sizes {
    .mb_#{$names}_pc,
    .mb_#{$names} {
      margin-bottom: $size;
    }
  }
  @each $names, $size in $sizes {
    .pt_#{$names}_pc,
    .pt_#{$names} {
      padding-top: $size;
    }
  }
  @each $names, $size in $sizes {
    .pb_#{$names}_pc,
    .pb_#{$names} {
      padding-bottom: $size;
    }
  }
}//pc


/*---------------------------------------------
フォントサイズの設定
---------------------------------------------*/
@media only screen and (max-width:$spwidth) {
  $fsizes:( XS:1rem, SS:1.2rem,S:1.4rem, M:1.8rem, L:2rem, LL:2.2rem, XL:2.5rem);
  @each $names, $size in $fsizes {
    .font_#{$names}_sp,
    .font_#{$names} {
      font-size: $size;
      // @if $fsizes == 1.8rem &&  $fsizes == 2rem &&  $fsizes == 2.2rem{
      //   line-height: 1.4;
      // }
    }
  }
}//sp
@media only screen and (min-width:$tbwidth) {
    $fsizes:( XS:1.2rem, SS:1.4rem, S:1.6rem, M:1.8rem, L:2rem, LL:2.6rem, XL:3.7rem);
  @each $names, $size in $fsizes {

    .font_#{$names}_pc,
    .font_#{$names} {
      font-size: $size;
    }
  }
}//sp

@mixin hover{
  @media only screen and (min-width: $tbwidth) {
    transition: .3s;
    &:hover{
      opacity:.7;
    }
  }//pc
}//@mixin hover

@mixin margin-auto{
  margin-left: auto;
  margin-right: auto;
}


/*# sourceMappingURL=main.css.map */
