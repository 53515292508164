/*---------------------------------------------
header
---------------------------------------------*/

.header{
  // height: 134px;
  border-bottom: 2px solid rgba(15, 113, 186,.7);
  background-color: $c-white;
  &Sub{
    text-align: center;
    padding-top: 10px;
    @media only screen and (min-width:$tbwidth) {
      text-align: right;
      padding-top: 15px;
      margin-right: 5px;
    }
    li{
      display: inline-block;
      vertical-align: middle;
      a{
        transition: .5s;
      }
      &.logoFu{
        margin-left: 25px;
        @media only screen and (max-width: $spwidth) {
          img{
            width: 90px;
            height: auto;
          }
        }
        @media only screen and (min-width:$tbwidth) {
          a:hover{
            opacity: .7;
          }
        }//pc
      }//.logoFu
      &:not(.logoFu){
        margin-top: 5px;
        margin-left: 10px;
        @media only screen and (min-width:$tbwidth) {
          a:hover{
            color: $c-main;
          }
        }//pc
      }//&:not(.logoFu)
      &.border{
        position: relative;
        padding-left: 12px;
        &::before{
          content: '';
          height: 1em;
          line-height: 1em;
          width: 1px;
          position: absolute;
          left: 0;
          top: 20%;
          display: block;
          border-left: 1px solid $c-font;
        }
      }//&.border
    }//li
  }//&Sub
  &Main{
    position: relative;
    margin-bottom: 15px;
    @media only screen and (max-width: $spwidth) {
      margin-top: 20px;
    }
    .logo{
      @media only screen and (max-width: $spwidth) {
        width: 200px;
        height: auto;
      }
    }
    .loginBtn{
      position: absolute;
      right: 130px;
      bottom: 0;
      a{
        padding: 8px 25px;
        border: 1px solid $c-main;
        border-radius: 5px;
        background-color: $c-main;
        color: $c-white;
        display: block;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,0.15);
        transition: .5s;
        @media only screen and (min-width:$tbwidth) {
          &:hover{
            background-color: $c-white;
            color: $c-main;
          }
        }//pc
      }//a
    }//.loginBtn
  }//&Main
}//.header


//グローバルナビ
.nav {
  position: fixed;
  //right: -320px; //これで隠れる
  bottom: 100%;
  width: 100vw; //スマホに収まるくらい
  height: 100vh;
  padding-top: 20px;
  @media only screen and (min-width:$tbwidth) {
    padding-top: 50px;
  }
  background-color: rgba(28,28,28,0.7);
  transition: all .6s;
  z-index: 200;
  overflow-y: auto; //ニューが多くなったらスクロールできるように
  &Box{
    text-align: center;
  }
  &List {
    padding-top: 10px;
    list-style: none;
    // margin-bottom: 75px;
  }
  &Item {
    @extend .f_bold;
    letter-spacing: .2em;
    text-align: center;
    padding: 10px;
    height: 50px;
    display: block;
    text-decoration: none;
    color: $c-white;
    transition: .3s;
    @media only screen and (max-width: $spwidth) {
      font-size: 1.8rem;
    }
    @media only screen and (min-width:$tbwidth) {
      font-size: 2.4rem;
      &:hover {
        color: $c-sub;
      }
    }
  }//&Item
}//.nav

.navBtn {
  position: absolute;
  @media only screen and (max-width: $spwidth) {
    right: 5px;
    top:  0;
  }
  @media only screen and (min-width:$tbwidth) {
    right: 35px;
    top: 15px;
  }
  width: 50px; //クリックしやすい幅
  height: 50px; //クリックしやすい高さ
  cursor: pointer;
  z-index: 300;
}//.navBtn
.navLine {
  position: absolute;
  right: 0;
  @media only screen and (min-width:$tbwidth) {
    right: 15px;
  }
  width: 34px;
  height: 4px;
  background-color: $c-main;;
  transition: all .6s;
  &_1 {
    top: 10px;
  }
  &_2 {
    top: 20px;
  }
  &_3 {
    top: 30px;
  }
}//.navLine

/* 表示された時用のCSS */
.nav-open{
  .nav {
    bottom:0;
  }
  .navLine{
    background-color: $c-white;
    &_1 {
      transform: rotate(45deg);
      top: 20px;
    }
    &_2 {
      width: 0;
      right: 50%;
    }
    &_3 {
      transform: rotate(-45deg);
      top: 20px;
    }
  }//.hamburger_line
  .navBtnFix{
    position: fixed;
    top:74px;
    right: 20px;
    @media only screen and (max-width: $spwidth) {
      top: 20px;
    }
    @media only screen and (min-width:$tbwidth) {
      right: 65px;
    }//tb
    @media only screen and (min-width:$pcwidth) {
      right: calc(50% - 550px);
    }//pc
  }
}//.nav-open
